






































import { Component, Vue } from 'vue-property-decorator';
import { projectModule } from '@/store/modules/project';
import { searchModule } from '@/store/modules/search';
import Footer from '@/components/Footer.vue';

@Component({
    components: {
        DialogPre: () => import('@/components/DialogPre.vue'),
        Footer
    }
})
export default class CustomerEmail extends Vue {
    get visible() {
        return searchModule.dialog;
    }

    set visible(val: boolean) {
        searchModule.closeOrOpenDialog(val);
    }

    get search() {
        return searchModule.searchSelected;
    }

    async mounted() {
        await projectModule.setEndpoint({
            project: 'email-busqueda/get-data-from-filter',
            models: 'email-busqueda/get-typologies-by-id-project/'
        });
        await searchModule.getById(this.$route.params.mailId);
    }
}
